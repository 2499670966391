import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import HomeHeader from "../../homePages/common/HomeHeader";
import {
  getStaffOnly,
  AddStafftipping,
  getStripePaypalCharge,
} from "../../customerInterface/Service/Auth.Service";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_BASEURL } from "../../../environment";
import "react-toastify/dist/ReactToastify.css";
import { Card, Modal } from "react-bootstrap";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DefaultImage from "../../../assets/images/defaultimage.png";
import trustaplogo from "../../../assets/images/trustapOriginalLogo.svg";

// import { toast } from "react-toastify";
import "react-phone-number-input/style.css";

function StaffTipping() {
  const userId = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState();
  const [payNext, setPaynext] = useState(false);
  const [customer_rating_to_staff, setCustomer_rating_to_staff] = useState(0);
  const userdata = useSelector((state) => state);
  const [showModel, setShowModel] = useState(false);
  const [payable, setpayable] = useState("");

  const [total, setTotal] = useState(0);
  const [error, setErrors] = useState({});
  const [orderID, setOrderID] = useState("");
  const [charges, setCharges] = useState({});
  // const [gateways, setGateways] = useState({});
  const [staffTiptotal, setStaffTipTotal] = useState(0);
  // const resto_id = userDetails?.resto_id;
  const resto_id = userDetails?.restoDetails?._id
    ? userDetails?.restoDetails?._id
    : userDetails?._id;
  const staff_id = userDetails?._id ? userDetails?._id : userId?.id;
  // const staff_id = userDetails?.staffDetails?._id
  //   ? userDetails?.staffDetails?._id
  //   : userDetails?._id;
  // const email = userDetails?.email;
  const staff_tip_amount = staffTiptotal;
  let customerId = userdata?.login?.data?.result?._id;
  let LoggedIn = userdata?.login?.isLoggedIn;
  let loginRole = userdata?.login?.data?.role;
  // let customerCountry = userdata?.login?.data?.result?.country;
  let staffCountry = userDetails?.country;
  // let staffCountry = userDetails?.staffDetails?.country
  //   ? userDetails?.staffDetails?.country
  //   : userDetails?.country;

  let currency = userDetails?.country === "gb" ? "£" : "€";

  let guestTipvar = 0;

  // {
    LoggedIn === true ? (guestTipvar = 0) : (guestTipvar = 1);
  // }

  const [values, set_values] = useState({
    customer_id: customerId,
    tip_amount: 5,
    donation_amount: 0,
    customer_comment: "",
    is_customer_commented: 1,
    is_group_tip: 1,
    group_tip_status: "",
    payment_amount: "",
    guest_email: "",
    guest_name: "",
    // guest_mobile: "",
    guest_country: "",
    guest_country_code: "",
    is_guest_registerd_user: 1,
    is_guest_tip: guestTipvar,
    country: "",
  });

  useEffect(() => {
    getByusersId();
    // __getStripePaypalCharge();
    if (loginRole === 0) {
      navigate("/super-admin/dashboard");
    }
    calc_total(values);
  }, [dispatch]);

  const getByusersId = () => {
    getStaffOnly(
      { _id: userId.id, type: userId.type },
      (data) => {
        setUserDetails(data?.data?.result);
      },
      dispatch
    );
  };
  const __getStripePaypalCharge = () => {
    getStripePaypalCharge((data) => {
      for (let charge of data?.result)
        if (charge.type === "TrustApp") {
          charges.trustAppCharges = charge.servicefees;
          charges.amount = charge.amount;
        }
    }, dispatch);
  };

  // let ProfilePath = `${API_BASEURL}/public/profile/${userDetails?.profile}`;

  let ProfilePath = userDetails?.profile
    ? `${API_BASEURL}/public/profile/${userDetails?.profile}`
    : `${API_BASEURL}/public/posts/${userDetails?.image}`;

  if (userId.type === "staff") {
    values.is_group_tip = 0;
  } else {
    values.is_group_tip = 1;
  }

  const ratingChanged = (newRating) => {
    setCustomer_rating_to_staff(newRating);
  };

  // const values_handler = (e) => {
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   let newErrors = { ...error }; // Create a copy of errors

  //   const newValues = {
  //     ...values,
  //     [name]: value,
  //   };

  //   set_values(newValues); // Calling the method to sum the value
  //   calc_total(newValues);
  //   tipamountstaff(newValues);

  //   if (newErrors[name]) {
  //     delete newErrors[name];
  //     setErrors(newErrors);
  //   }
  // };

  // const values_handler = (e) => {
  //   const { name, value } = e.target;
  //   let newErrors = { ...error }; // Create a copy of errors
  //   let selectedCountry;

  //   // Check if the selected field is "country"
  //   if (name === "country") {
  //     console.log("the value is ----", value);
  //     selectedCountry = countries.find((country) => country.code === value);
  //   }

  //   console.log("country", selectedCountry);
  //   // Update values with the new value for the input field
  //   const newValues = {
  //     ...values,
  //     [name]: value,
  //     ...(name === "country" && {
  //       guest_country: selectedCountry ? selectedCountry.code : "",
  //       guest_country_code: selectedCountry ? selectedCountry.code : "",
  //     }),
  //   };

  //   console.log("ol values:", newValues);

  //   // Update state with new values
  //   set_values(newValues);
  //   console.log(" Updatedvalues:", values);

  //   // Recalculate totals based on the new values
  //   calc_total(newValues);
  //   tipamountstaff(newValues);

  //   // Remove error for the specific field if it exists
  //   if (newErrors[name]) {
  //     delete newErrors[name];
  //     setErrors(newErrors);
  //   }
  // };

  const values_handler = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...error }; // Create a copy of errors

    const newValues = {
      ...values,
      [name]: value,
    };

    set_values(newValues);

    // Recalculate totals based on the new values
    calc_total(newValues);
    tipamountstaff(newValues);

    // Remove error for the specific field if it exists
    if (newErrors[name]) {
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  // };
  const calc_total = (newValues) => {
    const { tip_amount, donation_amount } = newValues;
    const newTotal = Number(tip_amount) + Number(donation_amount);
    setTotal(newTotal);
  };

  const tipamountstaff = (newValues) => {
    // const { tip_amount, service_charge } = newValues;
        const { tip_amount } = newValues;

    setStaffTipTotal(parseFloat(tip_amount).toFixed(2));
  };

  // const nextButtonHandler = async (e) => {
  //   const newErrors = validateForm();
  //   if (Object.keys(newErrors).length === 0) {
  //     setErrors(newErrors);
  //     // setShowModel(true);
  //   } else {
  //     setErrors(newErrors);
  //   }
  // };

  // function showPaynext(e) {
  //   setpayable(e);
  //   setPaynext(e);
  //   // setShowModel(false);
  //   window.scrollTo(0, 0); // Scroll to top
  // }

  async function showPaynext(e, obj) {
    const newErrors = validateForm();
    // console.log("errors", newErrors);

    // console.log("working");
    if (Object.keys(newErrors).length === 0) {
      setErrors(newErrors);

      if (obj) {
        setpayable(e);
        setPaynext(e);
        if (obj?.restaurant_name) {
          navigate(`/customer/restaurantprofilescreen/${obj?._id}`);
        } else {
          navigate(`/customer/staffprofile/${obj?._id}`);
        }
        window.scrollTo(0, 0);
      } else {
        setpayable(e);
        setPaynext(e);
        window.scrollTo(0, 0);
      }
    } else {
      setErrors(newErrors);
    }
  }

  const buttonHandler = async (e) => {
    values.paymentType = e;
    values.feesStatus = payable;
    values.customerFullname =
      LoggedIn === true
        ? userdata?.login?.data?.result?.full_name
        : values.guest_name;
    values.paymentAmount = payable ? total + charges?.trustAppCharges : total;
    values.totalAmount = payable
      ? parseFloat(
          parseFloat(charges?.trustAppCharges) + parseFloat(total)
        ).toFixed(2)
      : total;
    values.email = LoggedIn === true ? userDetails?.email : values.guest_email;
    // values.guest_country = LoggedIn === false && values.guest_country;
    // values.guest_country_code = LoggedIn === false && values.guest_country_code;
    values.guest_country = staffCountry;
    values.guest_country_code = staffCountry;
    values.country = staffCountry;

    // values.full_name = userDetails?.full_name ;
    values.full_name = userDetails?.full_name || userDetails?.restaurant_name;
    values.customerEmailAdd =
      LoggedIn === true
        ? userdata?.login?.data?.result?.email
        : userDetails?.email;

    // const newErrors = validateForm();

    // if (Object.keys(newErrors).length === 0) {
    //   setErrors(newErrors);

    const total_tip_amount = total;
    const res = await AddStafftipping(
      resto_id,
      values.customer_id,
      values.tip_amount,
      total_tip_amount,
      staff_id,
      values.donation_amount,
      values.service_charge,
      values.customer_comment,
      values.is_customer_commented,
      values.is_group_tip,
      customer_rating_to_staff,
      staff_tip_amount,
      values.guest_email,
      values.guest_name,
      values.guest_country,
      values.guest_country_code,
      // values.guest_mobile,
      values.is_guest_registerd_user,
      values.is_guest_tip,
      userDetails?.gatewayPlatform,
      values.paymentType,
      values.feesStatus,
      values.group_tip_status,
      values.customerFullname,
      values.paymentAmount,
      values.totalAmount,
      values.email,
      values.full_name,
      values.customerEmailAdd,
      values.country,
      dispatch
    );
    // setPaynext(false);
    if (res?.data?.status === true) {
      if (res?.data?.data?.url) {
        window.location.href = res?.data?.data?.url;
      } else {
        setOrderID(res?.data?.data?.id);
      }
    }
    // } else {
    //   setErrors(newErrors);
    // }
  };

  const validateForm = () => {
    const errors = {};

    if (!values.tip_amount) {
      errors.tip_amount = "Tip Amount is required";
    } else if (values.tip_amount <= 0) {
      errors.tip_amount = "Please enter valid tip amount";
    }

    // if (values.donation_amount && values.donation_amount < 0) {
    //   errors.donation_amount = "Please enter valid donation amount";
    // }

    if (LoggedIn === false) {
      if (!values.guest_name) {
        errors.guest_name = "Full name is required";
      }
      if (!values.guest_email) {
        errors.guest_email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(values.guest_email)) {
        errors.guest_email = "Email is invalid";
      }
      // if (!values.guest_country) {
      //   errors.guest_country = "Country is required";
      // }
      // if (!values.country) {
      //   errors.country = "Country is required";
      // }
      // if (values.guest_mobile) {
      //   if (

      //     values.guest_mobile.length < 13
      //   ) {
      //     errors.guest_mobile = "Contact number is invalid";
      //   }
      // }
    }

    return errors;
  };

  // const countries = [
  //   { code: "gb", name: "United Kingdom" },
  //   { code: "ie", name: "Ireland" },
  // ];

  const handeblur = () => {
    // const newErrors = validateForm();
        validateForm();

  };

  // let fullPaymentAmount = parseFloat(
  //   charges.amount + (charges?.stripeCharges / 100) * total
  // ).toFixed(2);

  return (
    <div className="staffProfile">
      <HomeHeader />
      <div className="container">
        <div className="row my-4">
          {/* <div className="col direction">
            <p class="mb-0 whiteText">
              <a
                onClick={() => navigate(`/customer/staffprofile/${userId.id}`)}
              >
                Home
              </a>{" "}
              <i class="fa-solid fa-angles-right whiteText"></i>{" "}
              <span className="whiteText">
                {userDetails?.full_name ||
                  userDetails?.restaurant_name}
              </span>
            </p>
          </div> */}
        </div>
        <div className="row profileData staffPostData">
          <div className="col-lg-6">
            <div className="profileContent">
              <div className="image">
                <img
                  src={
                    userDetails?.profile || userDetails?.image
                      ? ProfilePath
                      : DefaultImage
                  }
                  alt=""
                />
              </div>
              <div className="text">
                {/* <h1>{userDetails?.full_name}</h1> */}
                {/* {console.log("userdetails---------",userDetails)} */}
                <h1>
                  {userDetails?.restaurant_name
                    ? userDetails?.restaurant_name
                    : userDetails?.full_name}{" "}
                </h1>
                <p>PubStar</p>
                <div className="review">
                  <div className="star"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6"></div>
        </div>
        {LoggedIn === false && !payNext ? (
          <div className="ProfileSetting">
            <div className="container">
              <div className="row profileSettingConent">
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="inputField">
                        <label htmlFor="userName">Name</label>
                        <div className="inputBox blackBorder">
                          <input
                            type="text"
                            className="form-control px-3"
                            placeholder="Name"
                            name="guest_name"
                            onChange={values_handler}
                            onBlur={(e) => handeblur()}
                          />
                        </div>
                        {error.guest_name && (
                          <span className="text-danger">
                            {error.guest_name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="inputField">
                        <label htmlFor="userName">Email</label>
                        <div className="inputBox">
                          <input
                            type="email"
                            className="form-control px-3"
                            placeholder="Email"
                            name="guest_email"
                            onChange={values_handler}
                            onBlur={(e) => handeblur()}
                          />
                        </div>
                        {error.guest_email && (
                          <span className="text-danger">
                            {error.guest_email}
                          </span>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-md-6">
                      <div className="inputField1">
                        <label htmlFor="country">Country</label>
                        <div className="inputBox">
                          <select
                            name="country"
                            className="form-control px-3 countryCode countryCode2 inputField"
                            id="country"
                            // onChange={handleChange}
                            // onChange={handleCountryChange}
                            onChange={values_handler}
                            onBlur={(e) => handeblur()}
                            required
                          >
                            <option value="" className="inputBox">
                              Select a country
                            </option>
                            {countries.map((country) => (
                              <option key={country.code} value={country.code}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {error.country && (
                          <span className="text-danger">{error.country}</span>
                        )}
                      </div>
                    </div> */}

                    <div className="col-md-6">
                      {/* <div className="inputField1 ">
                    <label htmlFor="contact">Contact No</label>
                    <div className="inputBox  blackBorder">
                     <PhoneInput
                          className="form-control px-3"
                          name="contact_number"
                          international
                          maxLength={15}
                          defaultCountry="RU"
                          onChange={(e)=>handleChange1(e,"contact_number")}
                        
                        />
                    </div>
                    {error.guest_mobile && (
                          <span className="text-danger">
                            {error.guest_mobile}
                          </span>
                        )}
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row TippingBox tipping_pay_method mt-3">
          <div className="col-12">
            {/* <div className="row jcc dflex pt-3">
              <div className="col-4">
                {orderID && (
                  <PayPalScriptProvider
                    options={{
                      clientId:
                        "AbGGlInGbVMyv90a_BPqskFcajM4JY1tbhXd5hpUZMfGUrAGwVXquAxu55i3t8-sOkFxJwsLcKCDd-rv",
                      currency: "GBP",
                    }}
                  >
                    <PayPalButtons
                      style={{
                        shape: "rect",
                        //color:'blue' change the default color of the buttons
                        layout: "vertical", //default value. Can be changed to horizontal
                      }}
                      createOrder={() => orderID}
                      onApprove={async (data, actions) => {
                        updatePaypalPayment(data);
                      }}
                    />
                  </PayPalScriptProvider>
                )}
              </div>
            </div> */}
            {!orderID && !payNext && (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="inputField-inline">
                      <label for="tipping" className="form-label">
                        {/* Tip Amount (£) :  */}
                        Tip Amount ({currency}) :
                      </label>
                      {/* <div className="inputBox"> */}
                      <div className="">
                        <input
                          type="number"
                          name="tip_amount"
                          className="form-control"
                          id="inputEmail4"
                          value={values.tip_amount}
                          onChange={values_handler}
                          onBlur={(e) => handeblur()}
                        />
                        {error.tip_amount && (
                          <span className="text-danger">
                            {error.tip_amount}
                          </span>
                        )}
                        {/* <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id="tooltip2">
                              GoTipMe does not charge bartenders commissions or
                              fees.
                            </Tooltip>
                          }
                        >
                          <i class="fa-solid fa-circle-info"></i>
                        </OverlayTrigger> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="inputField-inline">
                      <label for="donation" className="form-label">
                        Platform Support (£) <span>(Optional)</span> :
                      </label>
                      <div className="inputBox">
                        <input
                          type="number"
                          class="form-control"
                          id="donation"
                          name="donation_amount"
                          onChange={values_handler}
                        />
                        {error.donation_amount && (
                          <span className="text-danger">
                            {error.donation_amount}
                          </span>
                        )}
                       
                      </div>
                    </div>
                  </div> */}

                  <div className="col-12">
                    <div className="inputField-inline bgLight">
                      <label for="totalAmount" className="form-label">
                        Total Amount ({currency}) :
                      </label>
                      <div className="inputBox">
                        <input
                          disabled
                          type="number"
                          class="form-control plane"
                          id="totalAmount"
                          value={
                            values?.donation_amount
                              ? total.toFixed(2)
                              : values?.tip_amount
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-12">
                    <div className="inputField-inline">
                      <label className="form-label">Rating :</label>
                      <div className="inputBox">
                        <ReactStars
                          count={5}
                          onChange={ratingChanged}
                          size={30}
                          // isHalf={true}
                          emptyIcon={<i className="far fa-star"></i>}
                          halfIcon={<i className="fa fa-star-half-alt"></i>}
                          fullIcon={<i className="fa fa-star"></i>}
                          activeColor="#ffd700"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="inputField-inline align-items-start">
                      <label for="totalAmount" className="form-label">
                        Add Feedback :
                      </label>
                      <div className="inputBox">
                        <textarea
                          name="customer_comment"
                          id=""
                          cols="50"
                          rows="4"
                          className="form-control"
                          onChange={values_handler}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {payNext && (
              <>
                <Card>
                  <div className="row p-4">
                    <div className="col-12">
                      <h3 className="Aeonik-Regular">Payment Method</h3>
                    </div>
                    <>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="net_pay_able">
                            <div className="">
                              <img
                                // src="https://www.stage.trustap.com/_next/static/media/trustap_logo.dee0e5cb.png"
                                src={trustaplogo}
                                height={27}
                                width={120}
                                border="0"
                                alt="Trustap Logo"
                              />
                              {/* <b> PayPal : </b> */}
                              {/* <h3>Trustap</h3> */}
                            </div>
                            <div className="">
                              {/* <p className="mb-0"> */}
                              <p className="payment-font">
                                Net Payable Amount :{" "}
                                <strong>
                                  {" "}
                                  {currency} &nbsp;
                                  {total.toFixed(2)}
                                </strong>
                                <br />
                                {/* {payable && (
                                <>
                                  Processing Charges ({charges?.trustAppCharges} ): £{" "}
                                  {parseFloat(charges?.trustAppCharges).toFixed(2)}
                                  <br />
                                </>
                              )} */}
                                {/* Net Payable Amount : £{" "}
                              {parseFloat(
                                payable
                                  ? charges?.trustAppCharges + total
                                  : total
                              ).toFixed(2)} */}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="col-md-2 col-12">
                            <div className="inputField">
                              <button
                                className="btn btn-main btn-height"
                                style={{}}
                                onClick={() => buttonHandler("TrustApp")}
                              >
                                Pay
                              </button>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </>
                    {/* )} */}
                  </div>
                </Card>
              </>
            )}
          </div>
        </div>
        {!orderID && !payNext && (
          <div className="row buttonsRow">
            <div className="navBtn"></div>
            <div className="btnGroups">
              <button
                className="btn btn-white-outline btn-height"
                // onClick={(e) => buttonHandlerpage(userDetails)}
                onClick={() => showPaynext(false, userDetails)}
              >
                Cancel
              </button>
              <button
                className="btn btn-main btn-height"
                style={{ width: "114.32px" }}
                // onClick={nextButtonHandler}
                onClick={() => showPaynext(true)}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
      <Modal show={showModel} onHide={() => setShowModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {/* <div className="modal-heading"> */}
            <h3>Payment</h3>
            {/* </div> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body ">
            <b>Would you like to continue with Tip ?</b>
            {/* <b>
              Would you like to pay the{" "}
              {gateways.TrustApp &&
                `TrustApp processing fees for the bartender, £${parseFloat(
                  charges.trustAppCharges ).toFixed(2)}`}{" "}
            </b> */}
            <div className="dflex jcc mt-4" style={{ gap: 15 }}>
              <button
                className="btn btn-main btn-height"
                onClick={() => showPaynext(false)}
              >
                No
              </button>
              <button
                className="btn btn-main btn-height"
                onClick={() => showPaynext(true)}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default StaffTipping;
