import HomeHeader from "../../homePages/common/HomeHeader";
import React, { useEffect, useState } from "react";
import {
  RestroRequestList,
  RestroAcceptedRequestList,
  CancelledRequestInvitation,
  RestroRejectedRequestList,
  RestroCancelledRequestList,
} from "../../staffInterface/service/Auth.service";
import { useDispatch, useSelector } from "react-redux";
import { pagination } from "../../../components/common/utils/message";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_BASEURL } from "../../../environment";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import DefaultImage from "../../../assets/images/defaultimage.png";

function SIInvitationRequest() {
  const [historyState, setHistoryState] = useState(1);
  const [restroRequestdata, setRestroRequestdata] = useState();
  const [restroacceptRequestdata, setAcceptRestroRequestdata] = useState();
  const [cancelledrestroresquest, setCancelledrestroRequest] = useState();
  const [rejectedrestroresquest, setRejectedrestroRequest] = useState();
  const [totalPages, settotalPages] = useState();
  const [acceptrequesttotalPages, setAcceptRequestTotalPages] = useState();
  const [rejectrequesttotalPages, setRejectRequestTotalPages] = useState();
  const [cancelledequesttotalPages, setCancelledTotalPages] = useState();

  const [cancelledrestroresquestlist, setCancelledrestroRequestList] =
    useState();
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [input, setInput] = useState({
    _id: cancelledrestroresquest,
    is_closed: 1,
    status: 3,
  });

  const onTabClick = (tabId) => () => {
    setHistoryState(tabId);
  };
  const userdata = useSelector((state) => state);
  // let staff_id = userdata?.login?.data?.result._id;

  let staff_id;
  let loginRole = userdata?.login?.data?.role;
  let user_id;
  if (loginRole == 3) {
    staff_id = userdata?.login?.data?.result?._id;
  }
  if (loginRole == 0) {
    staff_id = userdata?.adminstaff?.data?._id;
  }

  useEffect(() => {
    _getRestroRequestList();
    _getRestroAcceptedRequestList();
    _getRestrorejectedRequestList();
    _getRestrocancelledRequestList();
  }, [dispatch]);

  const handleUpdate = (_id) => {
    setCancelledrestroRequest(_id);
  };

  const cancelledButtonhandler = async () => {
    const res = await CancelledRequestInvitation(
      cancelledrestroresquest,
      input.is_closed,
      input.status
    );
    if (res.data.status == true) {
      toast.warn(
        "Request rejected successfully",
        {
          theme: "dark",
        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
      _getRestrocancelledRequestList()
      _getRestroRequestList()
      // window.location.reload()
      // window.location.href="/staff/invitationrequest"
      
    }
  };

  const _getRestroRequestList = () => {
    RestroRequestList(
      { page, limit, staff_id },
      (data) => {
        setRestroRequestdata(data?.docs);
        settotalPages(data?.totalPages);
      },
      dispatch
    );
  };

  const handleRequestInvitationPageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await RestroRequestList(
      { staff_id, page: currentPage, limit },
      (response) => {
        // console.log("ggggg" , response?.docs)
        setRestroRequestdata(response?.docs);
      },
      dispatch
    );
  };

  const _getRestroAcceptedRequestList = () => {
    RestroAcceptedRequestList(
      { page, limit, staff_id },
      (data) => {
        setAcceptRestroRequestdata(data?.docs);
        setAcceptRequestTotalPages(data?.totalPages);
      },
      dispatch
    );
  };

  const handleAcceptRequestInvitationPageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await RestroAcceptedRequestList(
      { staff_id, page: currentPage, limit },
      (response) => {
        setAcceptRestroRequestdata(response?.docs);
      },
      dispatch
    );
  };

  const _getRestrorejectedRequestList = () => {
    RestroRejectedRequestList(
      { page, limit, staff_id },
      (data) => {
        setRejectedrestroRequest(data?.docs);
        setRejectRequestTotalPages(data?.totalPages);
      },
      dispatch
    );
  };
  const handleRejectRequestInvitationPageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await RestroRejectedRequestList(
      { staff_id, page: currentPage, limit },
      (response) => {
        setRejectedrestroRequest(response?.docs);
      },
      dispatch
    );
  };

  const _getRestrocancelledRequestList = () => {
    RestroCancelledRequestList(
      { page, limit, staff_id },
      (data) => {
        setCancelledrestroRequestList(data?.docs);
        setCancelledTotalPages(data?.totalPages);
      },
      dispatch
    );
  };

  const handleCancelledRequestInvitationPageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await RestroCancelledRequestList(
      { staff_id, page: currentPage, limit },
      (response) => {
        setCancelledrestroRequestList(response?.docs);
      },
      dispatch
    );
  };

  const buttonviewHandlerpage = async (data) => {
    navigate(`/staff/restoviewprofile/${data?.resto_id}`);
  };
  return (
    <div className="invitationRequest">
      {/* <RIHeader /> */}
      <HomeHeader />
      <div className="container-fluid">
        <div className="row topBar">
          <div className="col">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content">
                    <div className="heading">
                      <h3 className="mb-0">Invitation Requests</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="tabs">
              {/* <div 
                className={`tab whiteText ${historyState === 0 && "active whiteText"}`}
                onClick={onTabClick(0)}
              >
                Pending Requests
                <div className="line"></div>
              </div> */}
              <div
                className={`tab whiteText ${historyState === 1 && "active whiteText"}`}
                onClick={onTabClick(1)}
              >
                Accepted
                <div className="line"></div>
              </div>
              <div
                className={`tab whiteText ${historyState === 2 && "active whiteText"}`}
                onClick={onTabClick(2)}
              >
                Rejected
                <div className="line"></div>
              </div>
              <div
                className={`tab whiteText ${historyState === 3 && "active whiteText"}`}
                onClick={onTabClick(3)}
              >
                Cancelled
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 0 && "d-none"}`}>
          <div className="col-12">
            <div className="row">
              <div className="col">
                {restroRequestdata &&
                  restroRequestdata?.map((data, index) => {
                    let ProfilePath = `${API_BASEURL}/public/posts/${data?.userDetails?.image}`;

                    return (
                      <div key={index} className="staffProfileList">
                        <div className="firstdata">
                          <div className="image">
                            <img
                              src={
                                data?.userDetails?.image != null ||
                                data?.userDetails?.image != undefined
                                  ? ProfilePath
                                  : DefaultImage
                              }
                              alt=""
                            />
                          </div>
                          <div className="text">
                            <p className="mb-0">
                              Request sent to
                              <span className="fw-bold">
                                {" "}
                                {data?.userDetails?.restaurant_name}
                              </span>{" "}
                              {/* ,{data?.userDetails?.addressLine1},{" "}
                              {data?.userDetails?.city} (
                              {data?.userDetails?.postcode}) */}
                            </p>
                          </div>
                          <div className="date">
                            <p className="mb-0">{data?.userDetails?.contact_number}</p>
                          </div>
                        </div>
                        <div className="secondData">
                          <button
                            className="btn btn-white-outline btn-height"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={(e) => {
                              handleUpdate(data?._id, e.target.value);
                            }}
                          >
                            Cancel Request
                          </button>
                          <div >
                            <a onClick={(e) => buttonviewHandlerpage(data)} className="blackText">
                              View Profile{" "}
                              <i class="fa-solid fa-angles-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {restroRequestdata?.length == 0 ? <p className="whiteText">No Records found</p> : ""}
            </div>
            <div className="row my-5">
              <div className="col">
                <nav aria-label="Page navigation example" class="paginationRow">
                  {totalPages > 1 ? (
                    <ReactPaginate
                      breakLabel={"..."}
                      nextLabel={"Next >"}
                      onPageChange={handleRequestInvitationPageChange}
                      pageCount={totalPages}
                      previousLabel={"< Back"}
                      renderOnZeroPageCount={null}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  ) : null}
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 1 && "d-none"}`}>
          <div className="col-12">
            <div className="row">
              <div className="col">
                {restroacceptRequestdata &&
                  restroacceptRequestdata?.map((data, index) => {
                    let ProfilePath = `${API_BASEURL}/public/posts/${data?.userDetails?.image}`;

                    return (
                      <div key={index} className="staffProfileList">
                        <div className="firstdata">
                          <div className="image">
                            <img
                              src={
                                data?.userDetails?.image != null ||
                                data?.userDetails?.image != undefined
                                  ? ProfilePath
                                  : DefaultImage
                              }
                              alt=""
                            />
                          </div>
                          <div className="text">
                            <p className="mb-0">
                              Request accepted to
                              <span className="fw-bold">
                                {" "}
                                {data?.userDetails?.restaurant_name}
                              </span>{" "}
                              {/* ,{data?.userDetails?.addressLine1},{" "}
                              {data?.userDetails?.city} ({" "}
                              {data?.userDetails?.postcode}) */}
                            </p>
                          </div>
                          <div className="date">
                            <p className="mb-0">{data?.userDetails?.contact_number}</p>
                          </div>
                        </div>
                        <div className="secondData">
                          <div className="link">
                            <a onClick={(e) => buttonviewHandlerpage(data)} className="blackText">
                              View Profile{" "}
                              <i class="fa-solid fa-angles-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {restroacceptRequestdata?.length == 0 ? <p className="whiteText">No Records found</p> : ""}
            </div>
            <div className="row my-5">
              <div className="col">
                <nav aria-label="Page navigation example" class="paginationRow">
                  {acceptrequesttotalPages > 1 ? (
                    <ReactPaginate
                      breakLabel={"..."}
                      nextLabel={"Next >"}
                      onPageChange={handleAcceptRequestInvitationPageChange}
                      pageCount={acceptrequesttotalPages}
                      previousLabel={"< Back"}
                      renderOnZeroPageCount={null}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  ) : null}
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 2 && "d-none"}`}>
          <div className="col-12">
            <div className="row">
              <div className="col">
                {rejectedrestroresquest &&
                  rejectedrestroresquest?.map((data, index) => {
                    let ProfilePath = `${API_BASEURL}/public/posts/${data?.userDetails?.image}`;

                    return (
                      <div key={index} className="staffProfileList">
                        <div className="firstdata">
                          <div className="image">
                            <img
                              src={
                                data?.userDetails?.image != null ||
                                data?.userDetails?.image != undefined
                                  ? ProfilePath
                                  : DefaultImage
                              }
                              alt=""
                            />
                          </div>
                          <div className="text">
                            <p className="mb-0">
                              Request rejected to
                              <span className="fw-bold">
                                {" "}
                                {data?.userDetails?.restaurant_name}
                              </span>{" "}
                              {/* ,{data?.userDetails?.addressLine1},{" "}
                              {data?.userDetails?.city} ({" "}
                              {data?.userDetails?.postcode}) */}
                            </p>
                          </div>
                          <div className="date">
                            <p className="mb-0">{data?.userDetails?.contact_number}</p>
                          </div>
                        </div>
                        <div className="secondData">
                          <div className="link">
                            <a onClick={(e) => buttonviewHandlerpage(data)} className="blackText">
                              View Profile{" "}
                              <i class="fa-solid fa-angles-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {rejectedrestroresquest?.length == 0 ? <p className="whiteText">No Records found</p> : ""}
            </div>
            <div className="row my-5">
              <div className="col">
                <nav aria-label="Page navigation example" class="paginationRow">
                  {cancelledequesttotalPages > 1 ? (
                    <ReactPaginate
                      breakLabel={"..."}
                      nextLabel={"Next >"}
                      onPageChange={handleRejectRequestInvitationPageChange}
                      pageCount={cancelledequesttotalPages}
                      previousLabel={"< Back"}
                      renderOnZeroPageCount={null}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  ) : null}
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 3 && "d-none"}`}>
          <div className="col-12">
            <div className="row">
              <div className="col">
                {cancelledrestroresquestlist &&
                  cancelledrestroresquestlist?.map((data, index) => {
                    let ProfilePath = `${API_BASEURL}/public/posts/${data?.userDetails?.image}`;
                    return (
                      <div key={index} className="staffProfileList">
                        <div className="firstdata">
                          <div className="image">
                            <img
                              src={
                                data?.userDetails?.image != null ||
                                data?.userDetails?.image != undefined
                                  ? ProfilePath
                                  : DefaultImage
                              }
                              alt=""
                            />
                          </div>
                          <div className="text">
                            <p className="mb-0">
                              Cancel Request sent to
                              <span className="fw-bold">
                                {" "}
                                {data?.userDetails?.restaurant_name}
                              </span>{" "}
                              {/* ,{data?.userDetails?.addressLine1},{" "}
                              {data?.userDetails?.city} ({" "}
                              {data?.userDetails?.postcode}) */}
                            </p>
                          </div>
                          <div className="date">
                            <p className="mb-0">{data?.userDetails?.contact_number}</p>
                          </div>
                        </div>
                        <div className="secondData">
                          <div className="link">
                            <a onClick={(e) => buttonviewHandlerpage(data)} className="blackText">
                              View Profile{" "}
                              <i class="fa-solid fa-angles-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {cancelledrestroresquestlist?.length == 0
                ? <p className="whiteText">No Records found</p>
                : ""}
            </div>
            <div className="row my-5">
              <div className="col">
                <nav aria-label="Page navigation example" class="paginationRow">
                  {rejectrequesttotalPages > 1 ? (
                    <ReactPaginate
                      breakLabel={"..."}
                      nextLabel={"Next >"}
                      onPageChange={handleCancelledRequestInvitationPageChange}
                      pageCount={rejectrequesttotalPages}
                      previousLabel={"< Back"}
                      renderOnZeroPageCount={null}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  ) : null}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body ">
              <div className="close" data-bs-dismiss="modal" aria-label="Close">
                <i class="fa-solid fa-xmark"></i>
              </div>
              <h3>Invitation Request</h3>
              <div className="otpBox">
                <p>Are you want to cancel the invitation</p>
              </div>
              <button
                className="btn btn-main btn-height"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={cancelledButtonhandler}
              >
                Yes
              </button>
              &nbsp;
              <button
                className="btn btn-white-outline btn-height"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SIInvitationRequest;
