import HomeHeader from "../../homePages/common/HomeHeader";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { pagination } from "../../../components/common/utils/message";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { getAllCustomerReports } from "../Service/Auth.service";
let start = 0;
function CustomerTipReport() {
  const userdata = useSelector((state) => state);
  let resto_id = userdata?.login?.data?.result?._id;
  const [totalPages, settotalPages] = useState();
  const [nooftotalreportlisttip, setNoOftotalReportListTip] = useState();

  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [allreportList, setAllReportList] = useState();
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();
  const maxDate = new Date().toISOString().split("T")[0];
  const [selectValue, setSelectValue] = useState();
  const [export1, setExportData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [historyState, setHistoryState] = useState(0);
  const onTabClick = (tabId) => () => {
    setHistoryState(tabId);
  };
  useEffect(() => {
    _getallReportList();
  }, [dispatch]);
  // --------------------------------------All reports ---------------------------------------------
  const _getallReportList = (endDate) => {
    getAllCustomerReports(
      { resto_id, page, limit, start_date, end_date: endDate },
      (data) => {
        setExportData(data?.export);
        setAllReportList(data?.result?.docs);
        settotalPages(data?.result?.totalPages);
        setNoOftotalReportListTip(data?.export);
      },
      dispatch
    );
  };

  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await getAllCustomerReports(
      { resto_id, page: currentPage, limit, start_date, end_date },
      (response) => {
        setAllReportList(response?.result?.docs);
      },
      dispatch
    );
  };
  const handleInputStartDate = (e) => {
    if (e.target.value == 0) {
      start = 0;
      setStartDate("");
      setPage(1);
      setSelectValue(e.target.value);
    } else {
      var filterDate = new Date(new Date().setHours(0, 0, 0, 0));
      filterDate.setDate(filterDate.getDate() - parseInt(e.target.value));
      start = format(new Date(filterDate), "yyyy-MM-dd");
      setPage(1);
      setStartDate(start);
      setSelectValue(e.target.value);
    }
  };

  useEffect(() => {
    let endDate = format(
      new Date(new Date().setHours(0, 0, 0, 0)),
      "yyyy-MM-dd"
    );
    _getallReportList(endDate);
  }, [start_date]);

  const data = [
    ["Staff Name", "Tip Type", "Date", "Day", "Time", "Tip Amount"],
    ...(export1 || [])
      .map((data) => [
        // ,

        data?.staffDetails?.full_name,
        data?.is_group_tip ? "Group Tip" : "Staff Tip",
        ` ${new Date(data?.createdAt).getFullYear()} / ${new Date(
          data?.createdAt
        )
          .getDate()
          .toString()
          .padStart(2, "0")}  / ${(new Date(data?.createdAt).getMonth() + 1)
          .toString()
          .padStart(2, "0")} `,
        new Date(data?.createdAt).toLocaleString("en-us", { weekday: "long" }),
        ` ${new Date(data?.createdAt).getHours()}: ${new Date(
          data?.createdAt
        ).getMinutes()}`,
        // new Date(data?.createdAt).toLocaleDateString(),
        data?.total_tip_amount,
      ])
      .reverse(),
  ];

  var totalallreport = 0;
  for (var i = 0; i < nooftotalreportlisttip?.length; i++) {
    totalallreport += nooftotalreportlisttip[i]?.total_tip_amount;
  }
        let currency =
          userdata?.login?.data?.result?.country === "gb" ? "£" : "€";


  return (
    <div className="tippingHistroy blackBackground">
      <HomeHeader />
      <div className="container-fluid">
        <div className="row topBar">
          <div className="col">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="heading">
                    <h1 className="Aeonik-Bold">Reports</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="tabs">
              {/* <div
                className={`tab ${historyState === 0 && "active"}`}
                onClick={onTabClick(0)}>
                All Reports
                <div className="line"></div>
              </div> */}
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 0 && "d-none"}`}>
          <div className="col-12">
            <div className="content">
              <div className="price">
                <h3>
                  {currency} {Math.round(totalallreport)}
                </h3>
                <p>Total Tips Paid</p>
              </div>
              <div className="col-md-3 col-12 webView"></div>
              <div className="col-md-1 col-12 webView"></div>
              <div className="col-md-3 col-12   webView">
                <div className="inputField ">
                  <div
                    className="inputBox blackBorder whiteBackground"
                    style={{ borderRadius: "30px" }}
                  >
                    <select
                      name="All Locations"
                      id=""
                      onChange={handleInputStartDate}
                      value={selectValue}
                      className="form-select px-3 form-control borderNone whiteBackground"
                      style={{ borderRadius: "30px" }}
                    >
                      <option value={0} selected>
                        All
                      </option>
                      <option value={7}>Weekly</option>
                      <option value={30}>Monthly</option>
                      <option value={365}>Yearly</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className="dates">
                                <div className="datePicker">
                                    <label htmlFor="pickFrom">From</label>
                                    <input type="date" name="" id="pickFrom" max={maxDate} className="form-control" onChange={handleInputStartDate} />
                                </div>
                                <div className="datePicker">
                                    <label htmlFor="pickTo">To</label>
                                    <input type="date" name="" id="pickTo" max={maxDate} className="form-control" onChange={handleInputEndDate} />
                                </div>
                            </div> */}
              <div className="col-md-3 col-12 webView">
                <div className="text-end">
                  {" "}
                  <CSVLink
                    data={data}
                    filename={"Tip-Report-data.csv"}
                    className="btn btn-height withdrawButton btn-main  dflex jcc alc "
                  >
                    <i className="fa-sharp fa-solid fa-download"></i>
                    Export Data (CSV Format){" "}
                  </CSVLink>
                </div>
              </div>
            </div>
          </div>

          <div className="table-wraper mt-4">
            <div className="top-action"></div>

            <Table responsive>
              <thead>
                <tr>
                  <th>Staff Name</th>
                  {/* <th>Customer Name</th> */}
                  <th>Type</th>
                  <th>Date</th>
                  <th>Day</th>
                  <th>Time</th>
                  <th className="text-center">Tipping Amount ({currency})</th>
                </tr>
              </thead>
              <tbody>
                {allreportList &&
                  allreportList?.map((data, index) => {
                    const date = new Date(data?.createdAt);

                    // Extract the day, month, and year values
                    const day = date.getDate().toString().padStart(2, "0");
                    const month = (date.getMonth() + 1)
                      .toString()
                      .padStart(2, "0");
                    const year = date.getFullYear();

                    // Format the date as dd/mm/yyyy
                    const formattedDate = `${day}/${month}/${year}`;
                    return (
                      <tr key={index}>
                        <td>
                          {data?.staffDetails && data?.staffDetails?.full_name}
                        </td>
                        {/* <td>
                                                    <p>{data?.is_guest_tip == true ? data?.guest?.guest_name : data?.customerDetails?.full_name}</p>
                                                    <p>
                                                       
                                                        {data?.is_guest_tip == true ? data?.guest.guest_email : data?.customerDetails?.email}
                                                    </p>
                                                </td> */}

                        <td>
                          {data?.is_group_tip == true
                            ? "Group Tip"
                            : "Staff Tip"}
                        </td>
                        <td>{formattedDate}</td>
                        <td>
                          {new Date(data?.createdAt).toLocaleString("en-us", {
                            weekday: "long",
                          })}
                        </td>
                        <td>
                          {new Date(data?.createdAt).getHours()}:{" "}
                          {new Date(data?.createdAt)
                            .getMinutes()
                            .toString()
                            .padStart(2, "0")}
                        </td>
                        <td className="text-center">
                          {data?.total_tip_amount.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {allreportList?.length == 0 ? "No Records found" : ""}
            </div>
            <div className="text-end d-flex">
              <Pagination className="ml-auto">
                {totalPages > 1 ? (
                  <ReactPaginate
                    breakLabel={"..."}
                    nextLabel={"Next >"}
                    onPageChange={handlePageChange}
                    pageCount={totalPages}
                    previousLabel={"< Back"}
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Pagination>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default CustomerTipReport;
