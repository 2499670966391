import HomeHeader from "../../homePages/common/HomeHeader";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { pagination } from "../../../components/common/utils/message";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllReports,
  getTotalStaffList,
  getTotalGroupTipList,
  getGroupTipNotDistributedList,
  getGroupTipDistributedList,
  getNoOfgetAllReports,
  getNoofTotalStaffList,
  getNooFTotalGroupTipList,
  getNooFTotalGroupNotDistributedTipList,
  getNooFTotalGroupDistributedTipList,
} from "../Service/Auth.service";
function Report() {
  const userdata = useSelector((state) => state);
  let resto_id = userdata?.login?.data?.result?._id;
  const [totalPages, settotalPages] = useState();

  const [nooftotalreportlisttip, setNoOftotalReportListTip] = useState();
  const [nooftotalstafflisttip, setNoOftotalstaffListTip] = useState();
  const [nooftotalgrouplisttip, setNoOftotalgroupListTip] = useState();
  const [
    nooftotalgroupnotdistributedlisttip,
    setNoOftotalgroupnotdistributedListTip,
  ] = useState();
  const [
    nooftotalgroupdistributedlisttip,
    setNoOftotalgroupdistributedListTip,
  ] = useState();

  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [allreportList, setAllReportList] = useState();
  const [allstaffList, setAllstaffList] = useState();
  const [stafftotalPages, setStafftotalPages] = useState();
  const [allgroupList, setAllgroupList] = useState();
  const [grouptotalPages, setGrouptotalPages] = useState();
  const [notdistributedtotalPages, setNotdistributedtotalPages] = useState();
  const [NotDistributedList, setNotDistributedList] = useState();
  const [distributedtotalPages, setdistributedtotalPages] = useState();
  const [DistributedList, setDistributedList] = useState();
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();
  const maxDate = new Date().toISOString().split("T")[0];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [historyState, setHistoryState] = useState(0);
  const onTabClick = (tabId) => () => {
    setHistoryState(tabId);
  };
  useEffect(() => {
    _getallReportList();
    _getallTotalStaffList();
    _getallTotalGroupList();
    _getTotalTipNotDistributedList();
    _getTotalTipDistributedList();
    _getNoOfallReportList();
    _getNoOfallStaffList();
    _getNoOfallGroupList();
    _getNoOfallGroupnotDistributedList();
    _getNoOfallGroupDistributedList();
  }, [dispatch]);

  // --------------------------------------All reports ---------------------------------------------
  const _getallReportList = (endDate) => {
    getAllReports(
      { resto_id, page, limit, start_date, end_date: endDate },
      (data) => {
        setAllReportList(data?.docs);
        settotalPages(data?.totalPages);
      },
      dispatch
    );
  };

  const _getNoOfallReportList = () => {
    getNoOfgetAllReports(
      { resto_id },
      (data) => {
        setNoOftotalReportListTip(data);
      },
      dispatch
    );
  };
  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await getAllReports(
      { resto_id, page: currentPage, limit, start_date, end_date },
      (response) => {
        setAllReportList(response?.docs);
      },
      dispatch
    );
  };
  const handleInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handleInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getallReportList(newDate.target.value);
  };

  const data = [
    ["Date", "Name", "Email", "Tip Type", "Tip Amount"],
    ...(allreportList || []).map((data) => [
      new Date(data?.createdAt).toLocaleDateString(),
      data?.is_guest_tip
        ? data?.guest?.guest_name
        : data?.customerDetails?.full_name,
      data?.is_guest_tip
        ? data?.guest.guest_email
        : data?.customerDetails?.email,
      data?.is_group_tip ? "Group Tip" : "Staff Tip",
      data?.total_tip_amount,
    ]),
  ];

  //   -----------------------------------Total Staff Tip --------------------------------------
  const _getNoOfallStaffList = () => {
    getNoofTotalStaffList(
      { resto_id },
      (data) => {
        setNoOftotalstaffListTip(data);
      },
      dispatch
    );
  };
  const _getallTotalStaffList = (endDate) => {
    getTotalStaffList(
      { resto_id, page, limit, start_date, end_date: endDate },
      (data) => {
        setAllstaffList(data?.docs);
        setStafftotalPages(data?.totalPages);
      },
      dispatch
    );
  };
  const handlestaffPageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await getTotalStaffList(
      { resto_id, page: currentPage, limit, start_date, end_date },
      (response) => {
        setAllstaffList(response?.docs);
      },
      dispatch
    );
  };
  const handlestaffInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handlestaffInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getallTotalStaffList(newDate.target.value);
  };
  const data1 = [
    ["Date", "Customer Name", "Customer Email", "Tip Amount"],
    ...(allstaffList || []).map((data) => [
      new Date(data?.createdAt).toLocaleDateString(),
      data?.is_guest_tip
        ? data?.guest?.guest_name
        : data?.customerDetails?.full_name,
      data?.is_guest_tip
        ? data?.guest.guest_email
        : data?.customerDetails?.email,

      data?.total_tip_amount,
    ]),
  ];
  //   --------------------------------------Total Group Tip ----------------------------------
  const _getNoOfallGroupList = () => {
    getNooFTotalGroupTipList(
      { resto_id },
      (data) => {
        setNoOftotalgroupListTip(data);
      },
      dispatch
    );
  };
  const _getallTotalGroupList = (endDate) => {
    getTotalGroupTipList(
      { resto_id, page, limit, start_date, end_date: endDate },
      (data) => {
        setAllgroupList(data?.docs);
        setGrouptotalPages(data?.totalPages);
      },
      dispatch
    );
  };
  const handleGroupPageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await getTotalGroupTipList(
      { resto_id, page: currentPage, limit, start_date, end_date },
      (response) => {
        setAllgroupList(response?.docs);
      },
      dispatch
    );
  };
  const handleGroupInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handleGroupInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getallTotalGroupList(newDate.target.value);
  };
  const data2 = [
    ["Date", "Customer Name", "Customer Email", "Tip Amount"],
    ...(allgroupList || []).map((data) => [
      new Date(data?.createdAt).toLocaleDateString(),
      data?.is_guest_tip
        ? data?.guest?.guest_name
        : data?.customerDetails?.full_name,
      data?.is_guest_tip
        ? data?.guest.guest_email
        : data?.customerDetails?.email,

      data?.total_tip_amount,
    ]),
  ];
  // --------------------------------------Group Tip (Not Distributed)--------------------------------
  const _getNoOfallGroupnotDistributedList = () => {
    getNooFTotalGroupNotDistributedTipList(
      { resto_id },
      (data) => {
        setNoOftotalgroupnotdistributedListTip(data);
      },
      dispatch
    );
  };
  const _getTotalTipNotDistributedList = (endDate) => {
    getGroupTipNotDistributedList(
      { resto_id, page, limit, start_date, end_date: endDate },
      (data) => {
        setNotDistributedList(data?.docs);
        setNotdistributedtotalPages(data?.totalPages);
      },
      dispatch
    );
  };
  const handleGroupNotdistributePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await getGroupTipNotDistributedList(
      { resto_id, page: currentPage, limit, start_date, end_date },
      (response) => {
        setNotDistributedList(response?.docs);
      },
      dispatch
    );
  };
  const handleGroupNotdistributeInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handleGroupNotdistributeInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getTotalTipNotDistributedList(newDate.target.value);
  };

  const data3 = [
    ["Date", "Customer Name", "Customer Email", "Tip Amount"],
    ...(NotDistributedList || []).map((data) => [
      new Date(data?.createdAt).toLocaleDateString(),
      data?.is_guest_tip
        ? data?.guest?.guest_name
        : data?.customerDetails?.full_name,
      data?.is_guest_tip
        ? data?.guest.guest_email
        : data?.customerDetails?.email,

      data?.total_tip_amount,
    ]),
  ];
  // ----------------------------------Group Tip (Distributed)---------------------------------------
  const _getNoOfallGroupDistributedList = () => {
    getNooFTotalGroupDistributedTipList(
      { resto_id },
      (data) => {
        setNoOftotalgroupdistributedListTip(data);
      },
      dispatch
    );
  };

  const _getTotalTipDistributedList = (endDate) => {
    getGroupTipDistributedList(
      { resto_id, page, limit, start_date, end_date: endDate },
      (data) => {
        setDistributedList(data?.docs);
        setdistributedtotalPages(data?.totalPages);
      },
      dispatch
    );
  };
  const handleGroupdistributePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await getGroupTipDistributedList(
      { resto_id, page: currentPage, limit, start_date, end_date },
      (response) => {
        setDistributedList(response?.docs);
      },
      dispatch
    );
  };
  const handleGroupdistributeInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handleGroupdistributeInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getTotalTipDistributedList(newDate.target.value);
  };
  const data4 = [
    ["Date", "Customer Name", "Customer Email", "Tip Amount"],
    ...(DistributedList || []).map((data) => [
      new Date(data?.createdAt).toLocaleDateString(),
      data?.is_guest_tip
        ? data?.guest?.guest_name
        : data?.customerDetails?.full_name,
      data?.is_guest_tip
        ? data?.guest.guest_email
        : data?.customerDetails?.email,

      data?.total_tip_amount,
    ]),
  ];
  var totalallreport = 0;
  for (var i = 0; i < nooftotalreportlisttip?.length; i++) {
    totalallreport += nooftotalreportlisttip[i]?.total_tip_amount;
  }
  var totalallstafftip = 0;
  for (var i = 0; i < nooftotalstafflisttip?.length; i++) {
    totalallstafftip += nooftotalstafflisttip[i]?.total_tip_amount;
  }

  var totalallgrouptip = 0;
  for (var i = 0; i < nooftotalgrouplisttip?.length; i++) {
    totalallgrouptip += nooftotalgrouplisttip[i]?.total_tip_amount;
  }

  var totalallgroupnotdistributedtip = 0;
  for (var i = 0; i < nooftotalgroupnotdistributedlisttip?.length; i++) {
    totalallgroupnotdistributedtip +=
      nooftotalgroupnotdistributedlisttip[i]?.total_tip_amount;
  }
  var totalallgroupdistributedtip = 0;
  for (var i = 0; i < nooftotalgroupdistributedlisttip?.length; i++) {
    totalallgroupdistributedtip +=
      nooftotalgroupdistributedlisttip[i]?.total_tip_amount;
  }

  let currency = userdata?.login?.data?.result?.country === "gb" ? "£" : "€";

    //   console.log("userdata?.login?.data--------", userdata);
    // console.log("userdata?.login?.data--------", userdata?.login?.data);


  // console.log("currency--------", currency);

  return (
    <div className="tippingHistroy blackBackground">
      <HomeHeader />
      <div className="container-fluid">
        <div className="row topBar">
          <div className="col">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="heading">
                    <h1 className="Aeonik-Bold">Reports</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="tabs report_tabs">
              <div
                className={`tab ${historyState === 0 && "active"}`}
                onClick={onTabClick(0)}
              >
                All Reports
                <div className="line"></div>
              </div>
              {/* <div
                className={`tab ${historyState === 1 && "active"}`}
                onClick={onTabClick(1)}
              >
                Total Pubstar Tip
                <div className="line"></div>
              </div> */}
              <div
                className={`tab ${historyState === 2 && "active"}`}
                onClick={onTabClick(2)}
              >
                Total Group Tip
                <div className="line"></div>
              </div>
              <div
                className={`tab ${historyState === 3 && "active"}`}
                onClick={onTabClick(3)}
              >
                Group Tip (Not Distributed)
                <div className="line"></div>
              </div>
              <div
                className={`tab ${historyState === 4 && "active"}`}
                onClick={onTabClick(4)}
              >
                Group Tip (Distributed)
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 0 && "d-none"}`}>
          <div className="col-12">
            <div className="content">
              <div className="price">
                <h3>
                  {currency}
                  {Math.round(totalallreport)}
                </h3>
                <p>Total Tips Received</p>
              </div>
              <div className="dates">
                <div className="datePicker">
                  <label htmlFor="pickFrom">From</label>
                  <input
                    type="date"
                    name=""
                    id="pickFrom"
                    max={maxDate}
                    className="form-control"
                    onChange={handleInputStartDate}
                  />
                </div>
                <div className="datePicker">
                  <label htmlFor="pickTo">To</label>
                  <input
                    type="date"
                    name=""
                    id="pickTo"
                    max={maxDate}
                    className="form-control"
                    onChange={handleInputEndDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-end">
            {" "}
            <CSVLink data={data} filename={"Tip-Report-data.csv"}>
              <i className="fa-sharp fa-solid fa-download"></i>
              Export Data (CSV Format){" "}
            </CSVLink>
          </div>
          <div className="table-wraper mt-4">
            <div className="top-action"></div>

            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Customer Name</th>
                  <th>Type</th>
                  <th>Tipping Amount ({currency})</th>
                </tr>
              </thead>
              <tbody>
                {allreportList &&
                  allreportList?.map((data, index) => {
                    const date = new Date(data?.createdAt);

                    // Extract the day, month, and year values
                    const day = date.getDate().toString().padStart(2, "0");
                    const month = (date.getMonth() + 1)
                      .toString()
                      .padStart(2, "0");
                    const year = date.getFullYear();

                    // Format the date as dd/mm/yyyy
                    const formattedDate = `${day}/${month}/${year}`;
                    return (
                      <tr key={index}>
                        <td>{formattedDate}</td>
                        <td>
                          <p>
                            {data?.is_guest_tip == true
                              ? data?.guest?.guest_name
                              : data?.customerDetails?.full_name}
                          </p>
                          <p>
                            {/* {data?.customerDetails?.email} */}
                            {data?.is_guest_tip == true
                              ? data?.guest.guest_email
                              : data?.customerDetails?.email}
                          </p>{" "}
                        </td>
                        <td>
                          {data?.is_group_tip == true
                            ? "Group Tip"
                            : "Staff Tip"}
                        </td>
                        <td>{data?.total_tip_amount.toFixed(2)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {allreportList?.length == 0 ? "No Records found" : ""}
            </div>
            <div className="text-end d-flex">
              <Pagination className="ml-auto">
                {totalPages > 1 ? (
                  <ReactPaginate
                    breakLabel={"..."}
                    nextLabel={"Next >"}
                    onPageChange={handlePageChange}
                    pageCount={totalPages}
                    previousLabel={"< Back"}
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Pagination>
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 1 && "d-none"}`}>
          <div className="col-12">
            <div className="content">
              <div className="price">
                <h3>
                  {currency} {Math.round(totalallstafftip)}
                </h3>
                <p>Total Pubstar Tip Received</p>
              </div>
              <div className="dates">
                <div className="datePicker">
                  <label htmlFor="pickFrom">From</label>
                  <input
                    type="date"
                    name=""
                    id="pickFrom"
                    max={maxDate}
                    className="form-control"
                    onChange={handlestaffInputStartDate}
                  />
                </div>
                <div className="datePicker">
                  <label htmlFor="pickTo">To</label>
                  <input
                    type="date"
                    name=""
                    id="pickTo"
                    max={maxDate}
                    className="form-control"
                    onChange={handlestaffInputEndDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-end">
            {" "}
            <CSVLink data={data1} filename={"Tip-Report-data.csv"}>
              <i className="fa-sharp fa-solid fa-download"></i>
              Export Data (CSV Format){" "}
            </CSVLink>
          </div>
          <div className="table-wraper mt-4">
            <div className="top-action"></div>
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Customer Name</th>
                  <th>Tipping Amount ({currency})</th>
                </tr>
              </thead>
              <tbody>
                {allstaffList &&
                  allstaffList?.map((data, index) => {
                    const date = new Date(data?.createdAt);

                    // Extract the day, month, and year values
                    const day = date.getDate().toString().padStart(2, "0");
                    const month = (date.getMonth() + 1)
                      .toString()
                      .padStart(2, "0");
                    const year = date.getFullYear();

                    // Format the date as dd/mm/yyyy
                    const formattedDate = `${day}/${month}/${year}`;
                    return (
                      <tr key={index}>
                        <td>{formattedDate}</td>
                        <td>
                          <p>
                            {data?.is_guest_tip == true
                              ? data?.guest?.guest_name
                              : data?.customerDetails?.full_name}
                          </p>
                          <p>
                            {/* {data?.customerDetails?.email} */}
                            {data?.is_guest_tip == true
                              ? data?.guest.guest_email
                              : data?.customerDetails?.email}
                          </p>{" "}
                        </td>
                        <td>{data?.total_tip_amount.toFixed(2)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {allstaffList?.length == 0 ? "No Records found" : ""}
            </div>
            <div className="text-end d-flex">
              <Pagination className="ml-auto">
                {stafftotalPages > 1 ? (
                  <ReactPaginate
                    breakLabel={"..."}
                    nextLabel={"Next >"}
                    onPageChange={handlestaffPageChange}
                    pageCount={stafftotalPages}
                    previousLabel={"< Back"}
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Pagination>
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 2 && "d-none"}`}>
          <div className="col-12">
            <div className="content">
              <div className="price">
                <h3>
                  {currency} {Math.round(totalallgrouptip)}
                </h3>
                <p>Total Group Tip Received</p>
              </div>
              <div className="dates">
                <div className="datePicker">
                  <label htmlFor="pickFrom">From</label>
                  <input
                    type="date"
                    name=""
                    id="pickFrom"
                    max={maxDate}
                    className="form-control"
                    onChange={handleGroupInputStartDate}
                  />
                </div>
                <div className="datePicker">
                  <label htmlFor="pickTo">To</label>
                  <input
                    type="date"
                    name=""
                    id="pickTo"
                    max={maxDate}
                    className="form-control"
                    onChange={handleGroupInputEndDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-end">
            {" "}
            <CSVLink data={data2} filename={"Tip-Report-data.csv"}>
              <i className="fa-sharp fa-solid fa-download"></i>
              Export Data (CSV Format){" "}
            </CSVLink>
          </div>
          <div className="table-wraper mt-4">
            <div className="top-action"></div>
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Customer Name</th>
                  <th>Tipping Amount ({currency})</th>
                </tr>
              </thead>
              <tbody>
                {allgroupList &&
                  allgroupList?.map((data, index) => {
                    const date = new Date(data?.createdAt);

                    // Extract the day, month, and year values
                    const day = date.getDate().toString().padStart(2, "0");
                    const month = (date.getMonth() + 1)
                      .toString()
                      .padStart(2, "0");
                    const year = date.getFullYear();

                    // Format the date as dd/mm/yyyy
                    const formattedDate = `${day}/${month}/${year}`;
                    return (
                      <tr key={index}>
                        <td>{formattedDate}</td>
                        <td>
                          <p>
                            {data?.is_guest_tip == true
                              ? data?.guest?.guest_name
                              : data?.customerDetails?.full_name}
                          </p>
                          <p>
                            {/* {data?.customerDetails?.email} */}
                            {data?.is_guest_tip == true
                              ? data?.guest.guest_email
                              : data?.customerDetails?.email}
                          </p>{" "}
                        </td>
                        <td>{data?.total_tip_amount.toFixed(2)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {allgroupList?.length == 0 ? "No Records found" : ""}
            </div>
            <div className="text-end d-flex">
              <Pagination className="ml-auto">
                {grouptotalPages > 1 ? (
                  <ReactPaginate
                    breakLabel={"..."}
                    nextLabel={"Next >"}
                    onPageChange={handleGroupPageChange}
                    pageCount={grouptotalPages}
                    previousLabel={"< Back"}
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Pagination>
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 3 && "d-none"}`}>
          <div className="col-12">
            <div className="content">
              <div className="price">
                <h3>
                  {currency} {Math.round(totalallgroupnotdistributedtip)}
                </h3>
                <p>Total Group Tip (Not Distributed)</p>
              </div>
              <div className="dates">
                <div className="datePicker">
                  <label htmlFor="pickFrom">From</label>
                  <input
                    type="date"
                    name=""
                    id="pickFrom"
                    max={maxDate}
                    className="form-control"
                    onChange={handleGroupNotdistributeInputStartDate}
                  />
                </div>
                <div className="datePicker">
                  <label htmlFor="pickTo">To</label>
                  <input
                    type="date"
                    name=""
                    id="pickTo"
                    max={maxDate}
                    className="form-control"
                    onChange={handleGroupNotdistributeInputEndDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-end">
            {" "}
            <CSVLink data={data3} filename={"Tip-Report-data.csv"}>
              <i className="fa-sharp fa-solid fa-download"></i>
              Export Data (CSV Format){" "}
            </CSVLink>
          </div>
          <div className="table-wraper mt-4">
            <div className="top-action"></div>
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Customer Name</th>
                  <th>Tipping Amount ({currency})</th>
                </tr>
              </thead>
              <tbody>
                {NotDistributedList &&
                  NotDistributedList?.map((data, index) => {
                    const date = new Date(data?.createdAt);

                    // Extract the day, month, and year values
                    const day = date.getDate().toString().padStart(2, "0");
                    const month = (date.getMonth() + 1)
                      .toString()
                      .padStart(2, "0");
                    const year = date.getFullYear();

                    // Format the date as dd/mm/yyyy
                    const formattedDate = `${day}/${month}/${year}`;

                    return (
                      <tr key={index}>
                        <td>{formattedDate}</td>
                        <td>
                          <p>
                            {data?.is_guest_tip == true
                              ? data?.guest?.guest_name
                              : data?.customerDetails?.full_name}
                          </p>
                          <p>
                            {/* {data?.customerDetails?.email} */}
                            {data?.is_guest_tip == true
                              ? data?.guest.guest_email
                              : data?.customerDetails?.email}
                          </p>{" "}
                        </td>
                        <td>{data?.total_tip_amount.toFixed(2)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {NotDistributedList?.length == 0 ? "No Records found" : ""}
            </div>
            <div className="text-end d-flex">
              <Pagination className="ml-auto">
                {notdistributedtotalPages > 1 ? (
                  <ReactPaginate
                    breakLabel={"..."}
                    nextLabel={"Next >"}
                    onPageChange={handleGroupNotdistributePageChange}
                    pageCount={notdistributedtotalPages}
                    previousLabel={"< Back"}
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Pagination>
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 4 && "d-none"}`}>
          <div className="col-12">
            <div className="content">
              <div className="price">
                <h3>
                  {currency} {Math.round(totalallgroupdistributedtip)}
                </h3>
                <p>Total Group Tip (Distributed)</p>
              </div>
              <div className="dates">
                <div className="datePicker">
                  <label htmlFor="pickFrom">From</label>
                  <input
                    type="date"
                    name=""
                    id="pickFrom"
                    max={maxDate}
                    className="form-control"
                    onChange={handleGroupdistributeInputStartDate}
                  />
                </div>
                <div className="datePicker">
                  <label htmlFor="pickTo">To</label>
                  <input
                    type="date"
                    name=""
                    id="pickTo"
                    max={maxDate}
                    className="form-control"
                    onChange={handleGroupdistributeInputEndDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-end">
            {" "}
            <CSVLink data={data4} filename={"Tip-Report-data.csv"}>
              <i className="fa-sharp fa-solid fa-download"></i>
              Export Data (CSV Format){" "}
            </CSVLink>
          </div>
          <div className="table-wraper mt-4">
            <div className="top-action"></div>
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Customer Name</th>
                  <th>Tipping Amount ({currency})</th>
                </tr>
              </thead>
              <tbody>
                {DistributedList &&
                  DistributedList?.map((data, index) => {
                    const date = new Date(data?.createdAt);

                    // Extract the day, month, and year values
                    const day = date.getDate().toString().padStart(2, "0");
                    const month = (date.getMonth() + 1)
                      .toString()
                      .padStart(2, "0");
                    const year = date.getFullYear();

                    // Format the date as dd/mm/yyyy
                    const formattedDate = `${day}/${month}/${year}`;
                    return (
                      <tr key={index}>
                        <td>{formattedDate}</td>
                        <td>
                          <p>
                            {data?.is_guest_tip == true
                              ? data?.guest?.guest_name
                              : data?.customerDetails?.full_name}
                          </p>
                          <p>
                            {/* {data?.customerDetails?.email} */}
                            {data?.is_guest_tip == true
                              ? data?.guest.guest_email
                              : data?.customerDetails?.email}
                          </p>{" "}
                        </td>
                        <td>{data?.total_tip_amount.toFixed(2)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {DistributedList?.length == 0 ? "No Records found" : ""}
            </div>
            <div className="text-end d-flex">
              <Pagination className="ml-auto">
                {distributedtotalPages > 1 ? (
                  <ReactPaginate
                    breakLabel={"..."}
                    nextLabel={"Next >"}
                    onPageChange={handleGroupdistributePageChange}
                    pageCount={distributedtotalPages}
                    previousLabel={"< Back"}
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Pagination>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Report;
