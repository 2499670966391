import axios from "axios";
import { apiUrl, PORT } from "../../../environment";
import { loadingAction } from "../../../components/common/loader/LoaderSlice";
// import { getInfo } from "../../auth/service/Auth.header";
import queryString from "query-string";

// let tokenString = localStorage.getItem('user');

// // Parse the token string to an object
// let token = JSON.parse(tokenString);
// let axiosConfig = {
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${token?.Token}`,
//   },
// };

export const restrobyId = async (_id) => {
  let token = localStorage.getItem("user");
  let token1 = JSON.parse(token);
  if (token1?.Token) {
    let axiosConfig = {
      headers: {
        Authorization: `Bearer ${token1?.Token}`,
      },
    };
    // console.log('.............staff')
    return axios.get(`${apiUrl}${PORT}/users/getbyid?_id=${_id}`, axiosConfig);
  }
};

export const RestroDetailsbyId = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/staff/getdetailsbyId?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const RestroSendrequest = async (
  resto_id,
  staff_id,
  description,
  staff_name,
  requestCode,
  status,
  is_closed,
) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      const response = await axios.post(
        `${apiUrl}${PORT}/restaurant/addinvitationrequest`,
        {
          resto_id,
          staff_id,
          description,
          staff_name,
          requestCode,
          status,
          is_closed,
        },
        axiosConfig
      );

      return response;
    }
  } catch (e) {
    return null;
  }
};

export const CancelledRequestInvitation = async (_id, is_closed, status) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      const response = await axios.put(
        `${apiUrl}${PORT}/restaurant/cancelledrestroinvitation`,
        {
          _id,
          is_closed,
          status,
        },
        axiosConfig
      );
      return response;
    }
  } catch (e) {
    console.log("e", e);
    return null;
  }
};

export const RestroRequestList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/restaurant/restaurantrequestlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const RestroAcceptedRequestList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/restaurant/restroacceptinvitationlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const RestroRejectedRequestList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/restaurant/restrorejectinvitationlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const RestroCancelledRequestList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/restaurant/cancelledrestroinvitationlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getRestroById = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/users/getbyid?${queryString.stringify(param)}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getrestoDetailsById = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/customer/getstaffprofilebyid?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const StaffGroupTipingHistory = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/staff/staffgrouptippinglist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const StaffViewProfileHistory = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/tip/getstaffTipDetailsById?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateTipping = async (_id, staff_comment, is_staff_commented) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      const response = await axios.put(
        `${apiUrl}${PORT}/tip/updatetip`,
        {
          _id,
          staff_comment,
          is_staff_commented,
        },
        axiosConfig
      );
      if (response.data.status) {
        return response;
      } else {
        return response;
      }
    }
  } catch (e) {
    return null;
  }
};

export const LeaveRequest = async (_id, status) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      const response = await axios.put(
        `${apiUrl}${PORT}/staff/restaurantleaverequest`,
        {
          _id,
          status,
        },
        axiosConfig
      );
      return response;
    }
  } catch (e) {
    console.log("e", e);
    return null;
  }
};

export const getStaffList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/staff/staffrestrolist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getMyPreviousJobList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/staff/mypreviousjoblist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          //  console.log("response.data.data",response);
          dispatch(loadingAction(false));
          callback(response);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getMyPreviousJobProfile = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/staff/getpreviousjobrestroprofile?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          //  console.log("response.data.data",response);
          dispatch(loadingAction(false));
          callback(response);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getCustomerTipList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/staff/getcustomertipbyid?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getRestroList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/users/userlist?${queryString.stringify(param)}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const NoofStaffTipinglist = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/staff/noofstafftippinglist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getnoCustomerTipList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/staff/getnoofcustomertipbyid?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const Customerandstafftiphistory = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/staff/customerandstafftiplist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const ContactSupportlistbyid = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/landingpage/contactsupportlistbyid?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const filterDashboardData = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/users/filterDashboardData?${queryString.stringify(param)}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};
export const fetchDashboardData = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/users/fetchDashboardData?${queryString.stringify(param)}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          // console.log("jjjjjj" , response?.data)
          callback(response?.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const addContactPage = async (formdata, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .post(
          `${apiUrl}${PORT}/landingpage/addcontactus`,
          formdata,
          axiosConfig
        )
        .then((response) => {
          callback(response);
          dispatch(loadingAction(false));
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

// export const getAllStaffList = async (param, callback, dispatch) => {
//   try {
//     let token = localStorage.getItem("user");
//     let token1 = JSON.parse(token);
//     if (token1?.Token) {
//       let axiosConfig = {
//         headers: {
//           Authorization: `Bearer ${token1?.Token}`,
//         },
//       };
//       dispatch(loadingAction(true));
//       axios
//         .get(
//           `${apiUrl}${PORT}/customer/getAllStaffList?${queryString.stringify(
//             param
//           )}`,
//           axiosConfig
//         )
//         .then((response) => {
//           dispatch(loadingAction(false));
//           callback(response?.data?.result);
//         })
//         .catch((error) => {
//           dispatch(loadingAction(false));
//           callback(false);
//         });
//     }
//   } catch (err) {
//     console.log(err);
//   }
// };

export const getAllPubList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/customer/getAllPubList?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const rejoinRequest = async (_id) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      const response = await axios.put(
        `${apiUrl}${PORT}/restaurant/rejoinpub`,
        {
          _id,
        },
        axiosConfig
      );
      return response;
    }
  } catch (e) {
    console.log("e", e);
    return null;
  }
};


export const joinRequest1 = async (_id ,description , requestCode,is_closed,status ) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      const response = await axios.put(
        `${apiUrl}${PORT}/restaurant/rejoinpub1`,
        {
          _id,description , requestCode  , is_closed , status
        },
        axiosConfig
      );
      return response;
    }
  } catch (e) {
    console.log("e", e);
    return null;
  }
};

export const addCountry = async (data) => {
  try {
    console.log("data", data);
    // const { userDetails } = data;
    // console.log("userDetails", userDetails);

    // dispatch(loadingAction(true));
    const response = await axios.put(`${apiUrl}${PORT}/users/addCountry`, data);
    if (response.data.status) {
      // dispatch(loadingAction(false));

      return response.data;
    } else {
      // dispatch(loadingAction(false));

      return response;
    }
  } catch (e) {
    return null;
  }
};
