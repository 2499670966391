import HomeHeader from "../../homePages/common/HomeHeader";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import {
  getRestrodetailsByid,
  AllstaffList,
  Resturant_Authenticate,
} from "../../customerInterface/Service/Auth.Service";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_BASEURL } from "../../../environment";
import { useNavigate } from "react-router-dom";
import { pagination } from "../../../components/common/utils/message";
import ReactPaginate from "react-paginate";
import DefaultImage from "../../../assets/images/defaultimage.png";
import restroDefaultImage from "../../../assets/images/restro-2.jpg"
import { Modal } from "react-bootstrap";
import ImageViewer from "react-simple-image-viewer";
function RestaurentProfileScreen() {
  const restroId = useParams();
  const [restroDetails, setRestroDetails] = useState();
  const [lastRecord, setLastRecord] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [showHome, setShowHome] = useState(true);
  const [searchPubstar, setSearchPubstar] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showTip, setShowTip] = useState(false);
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [totalPages, settotalPages] = useState();
  const navigate = useNavigate();
  const userdata = useSelector((state) => state);
  let loggedIn = userdata?.login?.isLoggedIn;
  const [input, setInput] = useState({
    sent_by_id: userdata?.login?.data?.result?._id,
    role: userdata?.login?.data?.result?.role,
    full_name: userdata?.login?.data?.result?.full_name,
    email: userdata?.login?.data?.result?.email,
    message: "",
    is_contact_support: 0,
    complaint_restro_id: restroId.id,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((previousValue) => ({
      ...previousValue,
      [name]: value,
    }));
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getByrestroId();
    _getNoofStaffListing();
  }, [dispatch]);

  const getByrestroId = () => {
    getRestrodetailsByid(
      { _id: restroId.id },
      (data) => {
        setRestroDetails(data?.data?.result);
        let record = data?.data?.result?.photoGallery.reverse().slice(0, 3);
        setLastRecord(record);
        if (
          data?.data?.result?.trustap_connected === true &&
          data?.data?.result?.trustap_user_id
        ) {
          // for (let user of data?.data?.result?.gatewayPlatform) {
          // if (user.status === "Active") {
          setShowTip(true);
          // }
          // }
        }
      },
      dispatch
    );
  };

  const _getNoofStaffListing = (searchText) => {
    AllstaffList(
      { resto_id: restroId.id, page, limit, searchText: searchText },
      (data) => {
        setStaffList(data?.data?.result?.docs);
        settotalPages(data?.data?.result?.totalPages);
      },
      dispatch
    );
  };
  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await AllstaffList(
      { resto_id: restroId.id, page: currentPage, limit },
      (response) => {
        setStaffList(response?.data?.result?.docs);
      },
      dispatch
    );
  };
  const __handleSearch = async (e) => {
    setSearchText(e.target.value);
    _getNoofStaffListing(e.target.value);
  };
  let ProfilePath = `${API_BASEURL}/public/posts/${restroDetails?.image}`;

  const buttonviewHandlerpage = async (data) => {
    if (!showTip) {
      toast.error("User not accepting Payments", {
        theme: "dark",
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    navigate(`/customer/stafftipping/${data?._id}/pub`);
  };

  const buttonviewHandlerpage1 = async (data) => {
    navigate(`/customer/staffprofile/${data?._id}`);
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const RestroAddAuthenticate = async (e) => {
    e.preventDefault();
    const res = await Resturant_Authenticate(
      input.sent_by_id,
      input.role,
      input.full_name,
      input.email,
      input.message,
      input.is_contact_support,
      input.complaint_restro_id
    );
    if (res.data.status == true) {
      toast.success("Submit successfully", {
        theme: "dark",
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    }
  };

  let ProfilePath1 = [];
  if (lastRecord) {
    for (let data of lastRecord) {
      ProfilePath1.push(`${API_BASEURL}/public/posts/${data?.image}`);
    }
  }

  const mapStyles = {
    height: "25vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: restroDetails?.lat || 51.901516,
    lng: restroDetails?.lng || -8.468283,
  };

  const locations = [
    {
      name: "Cask Pub and Kitchen",
      location: {
        lat: restroDetails?.lat || 51.901516,
        lng: restroDetails?.lng || -8.468283,
      },
    },

    ,
  ];

  const navigatetoback = () => {
    navigate("/customer/dashboard");
  };

const handleNavigateToStaffProfile = (data) => {
  const id = data?.userDetails?._id;
  navigate(`/customer/staffprofile/${id}`);
    window.scrollTo(0, 0);
};

  return (
    <div className="restaurentProfile blackBackground">
      <HomeHeader />
      <div className="container ">
        <div className="row">
          <div className="col-lg-2 col-12">
            <div className="row my-4">
              {/* <div className="col-12 direction jcs" style={{ gap: "10px" }}>
                {loggedIn == true ? (
                  <p
                    class="mb-0 whiteText"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowHome(true);
                      setSearchPubstar(false);
                    }}
                  >
                    <i class="fa-solid fa-house"></i>
                    Home
                  </p>
                ) : (
                  <>
                    <i
                      class="fa-solid fa-house"
                      onClick={() => {
                        setShowHome(true);
                        setSearchPubstar(false);
                      }}
                    ></i>
                    <p
                      class="mb-0 whiteText"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowHome(true);
                        setSearchPubstar(false);
                      }}
                    >
                      Home
                    </p>
                  </>
                )}
              </div> */}
              <div
                className="col-12 direction mt-3 jcs"
                style={{ gap: "10px" }}
              >
                {loggedIn == true ? (
                  <p
                    class="mb-0 whiteText"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSearchPubstar(true);
                      setShowHome(false);
                    }}
                  >
                    <i class="fa-solid fa-magnifying-glass"></i>
                    Search Pubstar
                    {/* {restroDetails?.restaurant_name} */}
                  </p>
                ) : (
                  <>
                    <i
                      class="fa-solid fa-magnifying-glass"
                      onClick={() => {
                        setSearchPubstar(true);
                        setShowHome(false);
                      }}
                    ></i>
                    <p
                      class="mb-0 whiteText"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSearchPubstar(true);
                        setShowHome(false);
                      }}
                    >
                      Search Pubstar
                      {/* {restroDetails?.restaurant_name} */}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-10 col-11 restroPublicprofile">
            {showHome && (
              <>
                <div className="row  ">
                  <div className="col-lg-3 roundShapeProfile">
                    <img
                      // src={ProfilePath ? ProfilePath : DefaultImage}
                      src={restroDetails?.image ? ProfilePath : restroDefaultImage}
                      alt=""
                      className="w-100 mainImage"
                    />
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <h1 className="whiteText Aeonik-Bold">
                      {" "}
                      {restroDetails?.restaurant_name}
                    </h1>
                    {restroDetails?.addressLine1 !== "" &&
                      restroDetails?.addressLine1 !== undefined && (
                        <>
                          <p className="whiteTexts Aeonik-Regular">
                            <i className="fa-solid fa-location-dot"></i>{" "}
                            {/* {restroDetails?.addressLine1}, */}
                            <span>
                              {" "}
                              {restroDetails?.city} ({restroDetails?.postcode})
                            </span>
                          </p>
                          <p className="whiteText Aeonik-Regular">
                            <i class="fa-solid fa-phone"></i>
                            {/* {restroDetails?.contact_number} */}
                            {restroDetails?.restaurantContact_number?.substr(
                              0,
                              3
                            ) +
                              "  " +
                              restroDetails?.restaurantContact_number?.substr(
                                3
                              )}
                          </p>
                          <p className="whiteText Aeonik-Regular">
                            <i className="fa-solid fa-envelope"></i>{" "}
                            {restroDetails?.email}
                          </p>
                        </>
                      )}

                    <button
                      className="btn btn-main btn-height"
                      onClick={(e) => buttonviewHandlerpage(restroDetails)}
                    >
                      Group Tip
                    </button>

                    <div
                      className="col-lg-8"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <p className="blackText Aeonik-Regular">
                        Are the pub details incorrect?
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-4 text-end mt-4 mt-lg-0">
                    {/* <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                >
                  <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={13}
                    center={defaultCenter}
                  >
                    {locations.map((item) => {
                      return <Marker key={item.name} position={item.location} />;
                    })}
                  </GoogleMap>
                </LoadScript> */}
                  </div>
                </div>

                <div className="row mt-5 mainDivofProfile ">
                  <span
                    style={{ fontSize: "x-large", marginBottom: "20px" }}
                    className="text"
                  >
                    Our Pubstars
                  </span>
                  <div className="co-12 roundShapeStaffProfile steps">
                    <div className="image-containers scroll">
                      {staffList &&
                        staffList?.map((data, index) => {
                          // let ProfilePath = `${API_BASEURL}/public/profile/${data?.userDetails?.profile}`;
                          return (
                            <div className="image">
                              <img
                                style={{ cursor: "pointer" }}
                                // onClick={(e) =>
                                //   navigate(
                                //     `/customer/staffprofile/${data?.userDetails?._id}`
                                //   )
                                // }
                                onClick={(e) =>
                                  handleNavigateToStaffProfile(data)
                                }
                                src={
                                  data?.userDetails?.profile
                                    ? `${API_BASEURL}/public/profile/${data?.userDetails?.profile}`
                                    : DefaultImage
                                }
                                alt=""
                              />
                              <p className="whiteText Aeonik-Regular text-center wordBreak">
                                {data.staff_name}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </>
            )}
            {searchPubstar && (
              <>
                <div className="row ">
                  <h2 className="Aeonik-Regular"></h2>
                  <div className="searchBox">
                    <input
                      type="text"
                      className="form-control"
                      value={searchText}
                      onChange={__handleSearch}
                    />
                  </div>
                </div>
                <div className="row ">
                  <div className="col">
                    <div className="row ">
                      {staffList &&
                        staffList?.map((data, index) => {
                          let ProfilePath = `${API_BASEURL}/public/profile/${data?.userDetails?.profile}`;

                          return (
                            <div className="col-md-6 col-12">
                              <div
                                key={index}
                                className="staffProfileList whiteBackground "
                              >
                                <div className="image">
                                  <img
                                    src={
                                      data?.userDetails?.profile != null ||
                                      data?.userDetails?.profile != undefined
                                        ? ProfilePath
                                        : DefaultImage
                                    }
                                    alt=""
                                  />
                                </div>
                                <p className="mb-0 blackText Aeonik-Regular">
                                  {data?.userDetails?.full_name}
                                </p>
                                <div className="text">
                                  <p>
                                    <a
                                      className="blackText Aeonik-Regular"
                                      onClick={(e) =>
                                        buttonviewHandlerpage1(
                                          data?.userDetails
                                        )
                                      }
                                    >
                                      View Profile{" "}
                                      <i class="fa-solid fa-angles-right"></i>
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* image div */}
            {showHome && (
              <>
                <hr className="whiteHr" />
                <div className="row galleryGap">
                  <span
                    style={{ fontSize: "x-large", marginBottom: "20px" }}
                    className="text"
                  >
                    Our Gallery
                  </span>
                  {lastRecord &&
                    lastRecord.map((result, index) => {
                      let ProfilePath = `${API_BASEURL}/public/posts/${result?.image}`;
                      return (
                        <div className="col-lg-4 dflex jcc">
                          <img
                            src={ProfilePath}
                            style={{
                              borderRadius: "10px",
                              cursor: "pointer",
                              objectFit: "cover",
                            }}
                            width={"92%"}
                            height={250}
                            alt=""
                            className=" mainImage"
                            onClick={() => openImageViewer(index)}
                          />
                        </div>
                      );
                    })}
                </div>
              </>
            )}
            {searchPubstar && (
              <>
                <div className="row mb-5 marginNone">
                  <div className="col">
                    <nav
                      aria-label="Page navigation example"
                      class="paginationRow"
                    >
                      {totalPages > 1 ? (
                        <ReactPaginate
                          breakLabel={"..."}
                          nextLabel={"Next >"}
                          onPageChange={handlePageChange}
                          pageCount={totalPages}
                          previousLabel={"< Back"}
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      ) : null}
                    </nav>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div className="close" data-bs-dismiss="modal" aria-label="Close">
                <i class="fa-solid fa-xmark"></i>
              </div>
              <form onSubmit={(e) => RestroAddAuthenticate(e)}>
                <div className="row">
                  <div className="col-10 text-start m-auto">
                    {/* <label htmlFor="writeConcern">Write your concern</label> */}
                    <textarea
                      type="text"
                      name="message"
                      id="writeConcern"
                      cols="30"
                      rows="5"
                      onChange={handleChange}
                      className="form-control my-2"
                    ></textarea>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <button
                      className="btn btn-main"
                      data-bs-dismiss="modal"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isViewerOpen && (
        <ImageViewer
          src={ProfilePath1}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </div>
  );
}

export default RestaurentProfileScreen;
