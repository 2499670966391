import HomeHeader from "../../homePages/common/HomeHeader";
import React, { useEffect, useState } from "react";
import { useDispatch,} from "react-redux";
import { useParams } from "react-router-dom";
import {
  getStaffTippinghistory,
} from "../../RestoInterface/Service/Auth.service";
import { API_BASEURL } from "../../../environment";

function TippingViewHistory() {
  const [viewProfileData, setViewProfileData] = useState();

  const staffId = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    _getStaffTippingHistoryUserbyId();
  }, [dispatch]);

  const _getStaffTippingHistoryUserbyId = () => {
    getStaffTippinghistory(
      { _id: staffId?.id },
      (data) => {
        setViewProfileData(data);
      },
      dispatch
    );
  };

  let ProfilePath = `${API_BASEURL}/public/profile/${viewProfileData?.StaffDetails?.profile}`;
  return (
    <>
      <div className="staffProfile">
        <HomeHeader />
        <div className="container">
          <div className="row my-4">
            <div className="col direction">
              <p class="mb-0 ">
                Home <i class="fa-solid fa-angles-right"></i>
                <span>{viewProfileData?.StaffDetails?.full_name}</span>
              </p>
            </div>
          </div>
          <div className="row profileData">
            <div className="col-lg-6">
              <div className="profileContent">
                <div className="image">
                  <img
                    src={
                      viewProfileData?.StaffDetails?.profile != null ||
                      viewProfileData?.StaffDetails?.profile != undefined
                        ? ProfilePath
                        : "../../assets/startProfile.png"
                    }
                    alt=""
                  />
                </div>
                <div className="text">
                  <h1>{viewProfileData?.StaffDetails?.full_name}</h1>
                  <p>Pubstar</p>
                  <div className="review">
                    {/* <div className="star">
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star-half"></i>
                    </div>
                    <p className="mb-0">(4.5)</p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 centerMid">
              {/* <div className="prices">
                <h3>$200</h3>
                <p className="mb-0">Total Tips Received</p>
               
              </div> */}
            </div>
            <div className="col-lg-6"></div>
          </div>
          <div className="row TippingBox">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="inputField-inline">
                    <label for="tipping" className="form-label">
                      Tip Amount ($) :
                    </label>
                    {viewProfileData?.total_tip_amount}
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12">
                  <div className="inputField-inline">
                    {/* <label className="form-label">Ratings :</label> */}
                    <div className="inputBox">
                      {/* <div className="stars">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star-half"></i>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="inputField-inline align-items-start">
                    <label for="totalAmount" className="form-label">
                      Feedback:
                    </label>
                    {viewProfileData?.customer_comment === ""
                      ? "-"
                      : viewProfileData?.customer_comment}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="inputField-inline">
                    <label className="form-label">
                      {/* {viewProfileData?.CustomerDetails?.full_name} */}
                      {viewProfileData?.CustomerDetails?.restaurant_name}
                    </label>
                    <div className="inputBox">
                      {/* <div className="stars">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star-half"></i>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {viewProfileData?.is_staff_commented ? (
                <div className="row">
                  <div className="col-12">
                    <div className="inputField-inline align-items-start">
                      <label for="totalAmount" className="form-label">
                        Pubstar Reply:
                      </label>

                      {viewProfileData?.staff_comment}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TippingViewHistory;
